import React from "react";
import NewsArticleLayout from "../../../components/updated-article/NewsArticleLayout.component";

const isNew = true;
const href = "/ratesheet/20240802-Rate-Sheet.pdf";
const date = "02.08.2024";

const Article = () => (
  <NewsArticleLayout href={href} date={date} isNew={isNew}>
    <h1 className="page-title">Changes to our mortgage products</h1>
    <p>
      <strong>
        Following the Monetary Policy Committee announcement on Thursday 1 August 2024, the Bank of England Base Rate
        has reduced from 5.25% to 5.00%.{" "}
      </strong>
    </p>
    <p>With effect from Friday 2 August 2024 the changes are as follows:</p>
    <ul>
      <li>All tracker rates will include the reduced Bank of England Base Rate.</li>
      <li>There are no changes to our Residential Standard Variable Rate or Buy to Let Standard Variable Rate.</li>
    </ul>
    <p>An updated mortgage rate sheet will be published on Friday 2 August 2024 to reflect these changes.</p>
  </NewsArticleLayout>
);

export default Article;
